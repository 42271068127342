@media print {
  header {
    display: none !important;
  }

  #content {
    top: 0 !important;
    overflow: hidden;
    background: url("/assets/resource/back_menuplan_eisenwerk.jpg") no-repeat center !important;
    background-size: 100%;
    min-height: 100%;
  }

  .footer {
    position: fixed;
    bottom: 0;
  }

  * {
    color: rgb(40, 40, 40) !important;
  }
}

.menuplan {;
  * {
    font-family: 'Comfortaa', cursive;
    color: rgb(80, 80, 80);
    text-align: center;
  }

  .language-flag-german {
    display: none;
  }
  .main {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
  }
  .header {
    height: 70px;
    width: 100%;
    font-size: 40px;
    margin-top: 50px;
  }
  .date {
    font-size: 20px;
  }
  .menus {
    margin: 35px 0 30px 0;
    min-height: 100px;
    width: 100%;
  }
  .menu {
    min-height: 100px;
    margin: 30px 0 5px 0;
    page-break-inside: avoid;
  }
  .menu-day {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .menu-detail {
    font-size: 20px;
    /*margin-bottom: 5px;*/
  }
  .footer {
    font-size: 10px;
    margin-top: 80px;
    height: 40px;
    width: 100%;
  }
  .logo {
    max-height: 200px;
  }
  .img-container {
    position: absolute;
    top: 0;
    max-height: 150px;
    width: 100%;
  }
  .seperator {
    /*display: none;*/
    height: 5px;
    width: 200px;
    margin: 3px auto 8px auto;
    border-bottom: 0.5px solid lightgray;
  }
  .seperator-sphere {
    position: relative;
    bottom: -1px;
    margin: auto;
    width: 7px;
    height: 7px;
    border: 0.8px solid lightgray;
    border-radius: 7px;
    background-color: white;
  }
}