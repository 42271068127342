//noinspection ALL
.decision-control-container {
  display: flex;
  flex-wrap: nowrap;
}

.decision-control-button {
  display: flex;
  cursor: pointer;
  height: 40px;
  width: 40px;
  margin: 0 10px;
  background: no-repeat center;
  background-size: 40px 40px;
}

.decision-control-button[disabled="disabled"] {
  cursor: not-allowed !important;
}

.decision-control-accept-continue {
  background-image: url("/assets/resource/svg/accept-continue.svg");
}
.decision-control-accept-continue[disabled="disabled"] {
  background-image: url("/assets/resource/svg/accept-continue-disabled.svg");
}

.decision-control-accept {
  background-image: url("/assets/resource/svg/accept.svg");
}
.decision-control-accept[disabled="disabled"] {
  background-image: url("/assets/resource/svg/accept-disabled.svg");
}

.decision-control-decline {
  background-image: url("/assets/resource/svg/decline.svg");
}