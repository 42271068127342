.validation-label {
  user-select: none;
  font-size: 20px;
  box-sizing: border-box;
  margin: 30px 0 14px 0;
  background: #fff;
  resize: none;
  outline: none;
  border-radius: 0;
  border-top: 1px solid #ddd;
  -webkit-appearance: none;
}

.validation-label > div > * {
  box-sizing: border-box;
}

.validation-label > div[data-ng-transclude] {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: stretch;
  width: 100%;
  height: 100%;
  padding: 10px 15px;
}

.validation-label-text {
  display: table;
  font-size: 18px;
  pointer-events: none;
  margin-left: 10px;
  margin-top: -10px;
  //width: 100%;
  padding: 0 5px;
  color: darkgrey;
  white-space: nowrap;
  background: linear-gradient(to bottom, #fff, #fff) no-repeat center;
}

.validation-label-text > div {
  display: flex;
}

.validation-label-required-image {
  margin-left: 10px;
  margin-top: -5px;
  margin-right: 5px;
  height: 25px;
  width: 25px;
  background-size: 25px, 25px, contain;
  background: url(/assets/resource/svg/required.svg) no-repeat center;
}

.validation-label-no-border {
  border-style: none !important;
  margin-top: 0;
}
//.validation-label.ng-invalid {
//  //noinspection LessResolvedByNameOnly
//  border-color: @nino-error-red !important;
//}
//
//.validation-label.ng-invalid > label:before {
//  //noinspection LessResolvedByNameOnly
//  color: @nino-error-red !important;
//}

.validation-label[disabled="disabled"] {
  color: black !important;
}

.validation-label > div > :focus,
.validation-label > div > :disabled  {
  border-style: none;
  outline: none;
  background: inherit;
}

.validation-label input,
.validation-label textarea,
.validation-label select {
  color: #097ff6;
}

.validation-label div[data-ng-class="invalid-value"],
.invalid-value {
  color: red !important;
}

*[validation-element] {
  border-style: none;
  width: 100%;
  margin: 0;
}

::-webkit-input-placeholder { /* WebKit browsers */
  text-transform: none;
  color: rgba(37, 135, 255, 0.40);
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  text-transform: none;
  color: rgba(37, 135, 255, 0.40);
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  text-transform: none;
  color: rgba(37, 135, 255, 0.40);
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
  text-transform: none;
  color: rgba(37, 135, 255, 0.40);
}

:disabled::-webkit-input-placeholder { /* WebKit browsers */
  color: #fff !important;
}
:disabled:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #fff !important;
}
:disabled::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #fff !important;
}
:disabled:-ms-input-placeholder { /* Internet Explorer 10+ */
  color: #fff !important;
}