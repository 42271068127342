.inno-action-control-container {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.action-control-item,
.action-control-item-medium {
  margin: 5px;
  width: 40px;
  height: 40px;
  background: no-repeat center;
  background-size: 40px, 40px, contain;
  cursor: pointer;
}

.action-control-item-medium {
  background-size: 30px 30px;
}


.toggle-button-show-items {
  background-image: url(/assets/resource/svg/expand.svg);
}

.toggle-button-hide-items {
  background-image: url(/assets/resource/svg/collapse.svg);
}

.action-items-container {
  display: flex;
  flex-wrap: nowrap;
}