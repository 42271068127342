.grouping-label {
  user-select: none;
  font-size: 20px;
  box-sizing: border-box;
  margin: 30px 0 14px 0;
  background: #fff;
  resize: none;
  outline: none;
  border-radius: 0;
  border-top: 1px solid #ddd;
  -webkit-appearance: none;
}

.grouping-label > div > * {
  box-sizing: border-box;
}

.grouping-label > div[data-ng-transclude] {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: stretch;
  width: 100%;
  height: 100%;
  padding: 10px 15px;
}

.grouping-label-text {
  display: table;
  font-size: 18px;
  pointer-events: none;
  margin-left: 10px;
  margin-top: -10px;
  //width: 100%;
  padding: 0 5px;
  color: darkgrey;
  white-space: nowrap;
  background: linear-gradient(to bottom, #fff, #fff) no-repeat center;
}

.grouping-label-text > div {
  display: flex;
}

.grouping-label-folded {
  margin: 30px 0 55px 0;
}
.grouping-label-fold-button {
  pointer-events: initial;
  margin-left: 10px;
  margin-top: -5px;
  margin-right: 5px;
  height: 30px;
  width: 30px;
  background-size: 30px, 30px, contain;
  background: no-repeat center;
}

.grouping-label-fold-button-folded {
  background-image: url(/assets/resource/svg/unfold.svg);
}

.grouping-label-fold-button-unfolded {
  background-image: url(/assets/resource/svg/fold.svg);
}

