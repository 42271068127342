.font-size-20px {
  font-size: 20px;
}

.font-weight-bold {
  font-weight: bold;
}

.flex-row,
.flex-column {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.stretch-children {
  align-items: stretch;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.margin-right-15 {
  margin-right: 15px !important;
}

.margin-left-15 {
  margin-left: 15px !important;
}

.width-300 {
  width: 200px;
  min-width: 200px;
}

.width-100px {
  width: 100px;
  min-width: 100px;
}

.full-width {
  width: 100%;
}

.one-fifth-width {
  width: 20%
}

.four-fifth-width {
  width: 80%;
}

.down-35-px {
  position: relative;
  top: 35px;
}

.full-height {
  height: 100%;
}

.border-box {
  box-sizing: border-box;
}

.height-150 {
  height: 135px;
}

.padding-top-10px {
  padding-top: 10px;
}

.padding-bottom-5px {
  padding-bottom: 5px;
}

.padding-right-15px {
  padding-right: 15px;
}

.padding-left-15px {
  padding-left: 15px;
}

.padding-right-25px {
  padding-right: 25px;
}

.padding-right-40px {
  padding-right: 40px;
}

.user-action-color {
  color: dodgerblue;
}

.resize-none {
  resize: none;
}

* {
  outline: none;
}

.highlighted {
  background-color: #ccff00;
}

*:disabled {
  background-color: white;
  color: black !important;
  cursor: default;
  opacity: 1;
}

select,
select:disabled{
  background-color: white;
  min-height: 32px;
}

.noscroll {
  overflow: hidden;
}

* {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  //noinspection CssUnknownProperty
  appearance: none !important;
}

textarea {
  padding: 1px 0;
  resize: none;
}

.date-input-field {
  padding: 5px 0;
  border: none;
  border-radius: 0;
  background-color: white;
  user-select: initial;
  outline: none;
  text-align: left;
}

.selectable {
  user-select: initial !important;
}

@media not print {
  .print-header, .print-header * {
    display: none;
  }
}

@media print {
  #content {
    padding: 0 !important;
    position: absolute;
    bottom: auto;
    z-index: inherit;
  }

  @page not(:first) header {
    header { display:none; }
  }

  .no-print, .no-print * {
    display: none !important;
  }

  .print-header, .print-header * {
    display: inherit !important;
  }

  .top-action * {
    display: none !important;
  }

  tr, tr * {
    page-break-inside: avoid !important;
  }
}

@media only screen and (max-width: 75em) {
  .hide-below-lg {
    display: none;
  }
}

@media only screen and (max-width: 64em) {
  .hide-below-md {
    display: none;
  }
}

@media only screen and (max-width: 48em) {
  .hide-below-sm {
    display: none;
  }
}

@media only screen and (min-width: 64em) {
  .hide-above-md {
    display: none;
  }
}

@media only screen and (min-width: 48em) {
  .hide-above-sm {
    display: none;
  }
}

.translate-cloak {
  display: none !important;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}