table {
  width: 100%;
  font-size: 20px;
  border-collapse:collapse;
  margin-bottom: 12px;
}

td {
  padding: 10px 0 10px 20px;
}

td:first-child {
  padding: 10px 0;
}

td img {
  width: 40px;
  height: 40px;
}

tr {
  border-bottom: 1px solid lightgrey;
}

tr:last-child {
  border-bottom: none;
}

.tr-no-border {
  border: none;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.table-caption {
  font-size: 30px;
  font-weight: 600;
}

