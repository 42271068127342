.notification-tile-container {
  user-select: none;
  font-size: 30px;
  line-height: 1.2;
  font-family: Arial, serif;
  position: relative;
  display: flex;
  flex: 0 0;
  flex-direction: column;
  width: 300px;
  height: 55px;
  box-shadow: 0 0 1px white inset, 2px 2px 8px rgba(0, 0, 0, 0.5);
  border: 1px solid white;
  margin: 20px;
}

.notification-tile-container:active {
  box-shadow: 0 0 1px white inset, 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.notification-tile-data-container {
  display: flex;
  flex: 0 0 110px;
  flex-direction: row;
}

.notification-tile-content-container {
  display: flex;
  flex: 0 0 185px;
  flex-direction: column;
  align-items: flex-end;
}

.notification-tile-color-bar {
  display: flex;
  flex: 0 0 10px;
}

.notification-tile-icon {
  display: flex;
  flex: 0 0 75px;
  max-width: 105px;
  max-height: 110px;
  padding: 15px;
}

.notification-tile-count {
  position: absolute;
  top: 15px;
  right: 5px;
  text-align: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid;
}

.notification-tile-text {
  position: absolute;
  bottom: 5px;
  right: 5px;
  max-width: 175px;
  text-align: right;
}

.notification-tile-img {
  width: 75px;
}

.notification-tile-type-warning {
  background-color: salmon;
}
.notification-tile-type-notification {
  background-color: lightblue;
}
.notification-tile-type-info {
  background-color: lightgreen;
}