@nino-light-blue: #99ccff;
@nino-dark-blue: #9999ff;
@nino-violet: #cc99ff;
@nino-pink: #ff99ff;
@nino-pink-red: #ff99cc;
@nino-red: #ff9999;
@nino-error-red: #ff7777;
@nino-orange: #ffcc99;
@nino-yellow: #ffff99;
@nino-light-green: #ccff99;
@nino-smooth-light-green: rgba(204,255,153,0.4);
@nino-green: #99ff99;
@nino-green-blue: #99ffcc;
@nino-cyan: #99ffff;
@nino-smooth-light-gray: rgb(245,245,245);

.nino-light-green-background {
  background-color: @nino-light-green;
}
.nino-smooth-light-green-background {
  background-color: @nino-smooth-light-green;
}
.nino-smooth-light-gray-background {
  background-color: @nino-smooth-light-gray;
}
.nino-orange-background {
  background-color: @nino-orange;
}
.nino-light-blue-background {
  background-color: @nino-light-blue;
}
.nino-red-background {
  background-color: @nino-red;
}
.nino-yellow-background {
  background-color: @nino-yellow;
}

.color-grey {
  color: grey;
}