.tile {
  min-width: 400px;
  max-width: 400px;
  margin: 10px;
  transition: max-width 0.1s, min-width 0.1s, margin 0.1s, opacity 0.1s;
  user-select: none;
}

.tile img {
  width: 100%;
  max-height: 100%;
}
.vertical-center {
  min-height: 100%;
  display: flex;
  align-items: center;
}

.tile-img-div {
  width: 200px;
  height: 180px;
}

.tile-text {
  font-size: 32px;
}

.tile div[class^='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.row {
  position: relative;
}

.bottom-align-text {
  position: absolute;
  bottom: 0;
  right: 0;
}

a > div[class*='tile'] {
  color: black;
}

div.tile-button {
  box-shadow: 0 0 1px white inset, 0 4px 10px rgba(0, 0, 0, 0.5);
  transition:box-shadow 0.1s;
}

div.tile-button:active {
  box-shadow: 0 0 1px white inset, 0 0 4px rgba(0, 0, 0, 0.2);
}