.pagination {
  display: flex;
  height: 50px;
  font-size: 25px;
}

.pagination > div {
  display: flex;
  width: 50px;
  margin: 3px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  //box-shadow: 1px 1px 4px lightgrey;
}