.notification-summary-tile-container {
  user-select: none;
  font-size: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 60px;
  margin-right: 15px;
}

.notification-summary-tile-color-bar {
  display: flex;
  flex: 0 0 5px;
}

.notification-summary-tile-icon {
  max-width: 45px;
  max-height: 30px;
  padding: 5px 0;
}

.notification-summary-tile-count {
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid black;
}

.notification-summary-tile-img {
  width: 40px;
  max-height: 30px;
}

.notification-summary-tile-type-warning {
  background-color: salmon;
}
.notification-summary-tile-type-notification {
  background-color: lightblue;
}
.notification-summary-tile-type-info {
  background-color: lightgreen;
}