* {
  font-family: 'ABeeZee', sans-serif;
}

#content {
  position: absolute;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 30px 10px 25px;
  overflow: auto;
  z-index: -1;
}

header,
.navigation,
.action,
.header-left-border,
.header-middle,
.header-right-border,
.main-menu-bar,
.sub-menu-bar {
  display: flex;
  user-select: none;
}

.navigation {
  height: 80px;
}

header {
  position: fixed;
  flex-direction: column;
  margin-top: 10px;
  height: 90px;
  width: 100%;
}

.header-left-border {
  background-color: rgb(135, 158, 66);
}

.header-left-border {
  width: 25px;
  min-width: 25px;
}

.header-left {
  flex-direction: column;
  margin-right: auto;
}

.header-right {
  flex-direction: column;
  margin-left: auto;
}

.header-right-border {
  width: 80px;
  height: 80px;
  background: #879e42 url(/assets/resource/logo.png) no-repeat center;
  background-size: contain;
}

.main-menu-bar {
  font-size: 22px;
  height: 40px;
  align-items: flex-end;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.sub-menu-bar {
  font-size: 28px;
  height: 42px;
  align-items: flex-end;
  text-transform: lowercase;
}

.align-right {
  justify-content: flex-end;
}

.menubar-item {
  user-select: none;
  margin: 0 10px;
  cursor: pointer;
}

.selected-menubar-item {
  margin: 0 10px -1px 10px;
  border-bottom: 1px solid black;
}
.menubar-item:hover {
  margin: 0 10px -1px 10px;
  border-bottom: 1px solid black;
}

.header-menu-dropdown {
  position: absolute;
  top: 60px;
  padding: 10px 25px;
  font-weight: normal;
  box-shadow: 0 20px 30px -12px rgba(204,255,153,0.4) inset, 0 20px 30px -12px white inset;
}

.dropdown-left {
  left: 0;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}
.dropdown-right {
  right: 0;
  border-left: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.menu-icon {
  padding: 10px;
  width: 40px;
  height: 40px;
}

.menu-item {
  cursor: pointer;
  font-size: 20px;
}

.menu-item:hover {
  font-weight: bold;
}

.menu-dropdown-item,
.menu-dropdown-sub-item {
  font-size: 26px;
}

.menu-dropdown-item {
  padding: 15px 0;
}

.menu-dropdown-sub-item {
  padding: 15px 0 15px 20px;
}

.menu-dropdown-item:hover,
.menu-dropdown-sub-item:hover {
  font-weight: bold;
}

.top-action,
.top-filter-container-message,
.top-filter-container,
.top-filter-container-date-range,
.top-action-container,
.top-action-item,
.top-filter-item {
  user-select: none;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.top-action {
  padding: 15px 20px;
  justify-content: flex-end;
}


.top-filter-container-message,
.top-filter-container,
.top-filter-container-date-range {
  flex: 1 1 230px;
  user-select: none;
  margin-right: auto;
  justify-content: flex-start;
}

.top-filter-container-message {
  color: red;
}

.top-filter-container {
  max-width: 230px;
}

.filter-input-field {
  padding: 5px 0;
  border: none;
  border-bottom: 1px solid lightgrey;
  border-radius: 0;
  background-color: white;
  width: 100%;
  user-select: initial;
  outline: none;
}

.filter-select-field {
  margin-left: 20px;
  border: none;
  border-radius: 0;
  background-color: white;
  width: 100%;
  user-select: initial;
  color: dodgerblue;
  outline: none;
}

.filter-delete-button {
  cursor: pointer;
  height: 33px;
  width: 36px;
  background: url(/assets/resource/svg/clear.svg) no-repeat center;
  background-size: 20px 20px;
}

.action-hint {
  color: dodgerblue;
  margin-right: 5px;
}

.top-filter-item {
  white-space: nowrap;
}

.top-action-container {
  flex: 1 0;
  flex-wrap: nowrap;
  width: 130px;
  padding-right: 5px;
  justify-content: flex-end;
}

.top-action-item {
  cursor: pointer;
  white-space: nowrap;
}

.top-action-image {
  cursor: pointer;
  max-width: 40px;
  height: 40px;
}

.top-action-image-medium {
  cursor: pointer;
  max-width: 30px;
  height: 30px;
}

.language-flag-german {
  background: url(/assets/resource/austrian.png) no-repeat center;
}
.language-flag-english {
  background: url(/assets/resource/english.png) no-repeat center;
}

.language-flag-german,
.language-flag-english {
  cursor: pointer;
  width: 33px;
  height: 23px;
  background-size: contain;
  margin: 0 10px 5px 0;
}

.nino-logotype {
  height: 200px;
  width: 800px;
  margin-bottom: 10%;
  background: url(/assets/resource/svg/cookandeat_desk.svg) no-repeat center;
  background-size: contain;
}